th,
td {
  padding: 10px;
}

.vf-table {
  border-radius: 4px 4px 0 0;
  overflow: hidden;
  /* width: 100%; */
}

thead {
  background-color: #acd4ff;
}

tr th,
tr td {
  padding: 8px 10px;
}

tr {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

tr th {
  padding: 10px 10px;
}
/* .patient-table td:first-child {
  border-bottom-left-radius: 10px;
} */

/* .case-table thead th:nth-child(1) {
  width: 10%;
}

.case-table thead th:nth-child(2) {
  width: 10%;
}

.case-table thead th:nth-child(3) {
  width: 10%;
}
.case-table thead th:nth-child(4) {
  width: 10%;
}
.case-table thead th:nth-child(5) {
  width: 10%;
} */
