:root {
  --primary: rgb(40 66 85); /* #284255 */
  --pg_primary__p: #2470c2;
  --secondary: #b4a79660;
  overflow-x: hidden;
  box-sizing: content-box;
  font-family: "Nunito Sans", sans-serif !important;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: content-box;
  background-color: #fff;
}

.text-primary {
  color: var(--primary) !important;
  /* color: #09113b !important; */
}
.bg-primary {
  background: var(--primary) !important;
  /* color: #09113b !important; */
}

.btn:disabled {
  cursor: not-allowed !important;
  /* background-color: red !important; */
}

.btn {
  text-transform: uppercase;
  font-weight: 700;
  border: none !important;
  height: 50px;
}

.btn-sm {
  font-size: 13px;
  height: 32px;
}
.btn-primary {
  background: var(--primary) !important;
}

.btn-secondary {
  color: var(--primary) !important;
  background: #f5f6f8 !important;
}

.cp {
  cursor: pointer;
}

.br {
  border-radius: 8px;
}
.btrr {
  border-top-right-radius: 8px;
}
.btlr {
  border-top-left-radius: 8px;
}
.bbrr {
  border-bottom-right-radius: 8px;
}
.bblr {
  border-bottom-left-radius: 8px;
}
.br-sm {
  border-radius: 4px;
}
.br-lg {
  border-radius: 12px;
}

.transition-all {
  transition: all 0.3s ease-in-out !important;
}

.gradient {
  background: #de6262; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #ffb88c,
    #de6262
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #ffb88c,
    #de6262
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
