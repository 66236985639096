.sidebar {
  left: 0;
  top: 0;
  height: 100vh;
  width: 95%;
  /* box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12); */
  z-index: 1050;
  background-color: #28425510 !important;
  padding: 10px;
  transition: width 0.3s ease-in-out;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  /* overflow-y:scroll ; */
  /* overflow-x: hidden; */
}
.sidebar::-webkit-scrollbar {
  display: none;
}
.prac-menu__item {
  position: relative;
  font-size: 18px !important;
  color: #495057;
  padding: 0px 4px;
  white-space: nowrap;
  text-overflow: clip;
  font-weight: normal !important;
}

.prac-menu__item:hover {
  font-weight: bold !important;
  color: #000;
}
.prac-menu__item.active {
  font-weight: bold !important;
  color: #000;
  background: rgba(40, 66, 85, 0.3);
  border-radius: 10px;
}

.prac-menu__item.active::after {
  content: "";
  position: absolute;
  top: 18px;
  left: -10px;
  height: 12px;
  width: 6px;
  background: rgba(238, 156, 4, 0.5);
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.prac-menu__arrow {
  position: absolute;
  top: 20px;
  font-weight: bold;
  right: -20px;
  cursor: pointer;
  z-index: 900;
  /* box-shadow: 0 0 15px rgb(0 0 0 / 15%); */
}

.prac-menu__item .prac-menu__icon {
  font-size: 28px !important;
}
.prac-menu__item .prac-menu__name {
  font-size: 14px;
  line-height: 36px !important;
  vertical-align: text-bottom;
}
.navbar-container {
  display: inline-block;
  width: 250px;
}
.main-container {
  display: inline-block;
  width: calc(100% - 250px);
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
}
.navbar-container.collapsed {
  width: 75px;
}
.main-container.collapsed {
  width: calc(100vw - 75px);
  /* width: 100vw; */
}

@media screen and (max-width: 1024px) {
  .navbar-container.collapsed {
    width: 75px;
  }
  .main-container.collapsed {
    width: 100%;
  }
  .prac-menu__name {
    font-size: 12px;
  }
}

.card .view.gradient-card-header {
  padding: 1rem 1rem;
  text-align: center;
}

.card h3,
.card.card-cascade h4 {
  margin-bottom: 0px;
}

.cascading-admin-card .admin-up {
  margin-left: 4%;
  margin-right: 4%;
  margin-top: -20px;
}

.cascading-admin-card .admin-up i {
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.2), 0 2px 13px 0 rgba(0, 0, 0, 0.19);
}

.cascading-admin-card .admin-up i {
  padding: 1.7rem;
  font-size: 2rem;
  color: #fff;
  text-align: left;
  margin-right: 1rem;
  border-radius: 3px;
}

.cascading-admin-card .admin-up .data {
  float: right;
  margin-top: 2rem;
  text-align: right;
}
.admin-up .data p {
  color: #999999;
  font-size: 12px;
}
.classic-admin-card .card-body {
  color: #fff;
  margin-bottom: 0;
  padding: 0.9rem;
}
.classic-admin-card .card-body p {
  font-size: 13px;
  opacity: 0.7;
  margin-bottom: 0;
}
.classic-admin-card .card-body h4 {
  margin-top: 10px;
}

.form-inline {
  flex-flow: unset;
}

.breadcrumb {
  margin: 0;
}

.page-footer {
  margin-left: 270px;
}

@media (max-width: 1199.98px) {
  .sidebar-fixed {
    display: none;
  }
  .flexible-content {
    padding-left: 0;
  }
  .flexible-navbar {
    padding-left: 10px;
  }

  #content {
    margin-left: 0px;
  }

  .page-footer {
    margin-left: 0px;
  }

  .card.cascading-admin-card {
    margin-top: 2.5rem;
  }
}

@media (max-width: 576px) {
  .card.cascading-admin-card {
    margin-top: 2.5rem;
  }

  #breadcrumb {
    flex-direction: column;
  }
}

.ant-select,
.ant-picker,
.ant-picker:hover,
.ant-picker-focus,
.ant-select-selector {
  border: 2.5px solid var(--primary);
  border-radius: 10px;
  overflow: hidden;
  height: 50px !important;
  width: 100% !important;
}
.ant-select-selector {
  border: none !important ;
}
.ant-select-selector,
.ant-select-selection-search,
.ant-select-selection-placeholder,
.ant-select-selection-search-input,
.ant-select-selection-item {
  border-radius: 10px;
  line-height: 45px !important;
  height: 50px !important;
}
.ant-select-arrow {
  font-weight: bold;
}
.ant-card-body {
  padding: 0 !important;
}
.ant-modal-content,
.ant-modal-header {
  border-radius: 12px !important;
}
.client-card {
  transition: transform .3s ease-in-out;
}
.client-card:hover{
  transform: translateY(-10px);
}

.client-card__icon {
  border: 2px solid #d1d2d3;
  cursor: pointer;
  font-size: 22px;
  border-radius: 50%;
  height: 35px;
  width: 35px;
  margin: 5px 2px;
  text-align: center;
  vertical-align: text-bottom;
  line-height: 36px;
  box-shadow: 0 0 10px rgb(0 0 0 / 20%);
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.setting-card {
  background: rgba(0, 0, 0, 0.02);
  overflow: hidden;
  font-size: 20px;
  padding: 10px;
  line-height: 36px;
  box-shadow: 0 0 15px rgb(0 0 0 / 20%);
  transition: transform 0.3s ease-in-out;
}
.setting-card:hover {
  transform: translateY(-5px);
}

.ant-table-thead {
  border-radius: 10px !important;
  overflow: hidden;
}
.ant-table-thead > tr > th {
  background-color: var(--primary) !important;
  color: #fff;
}

.ant-dropdown {
}

.ant-menu {
  /* background-color: red; */
  box-shadow: 0 0 15px rgb(0 0 0 / 35%) !important;
}

.online-dot {
  position: relative;
}
.online-dot::after {
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  top: 30%;
  left: 3px;
  border-radius: 50%;
  background-color: green;
}
.ant-tabs-tab-btn {
  color: var(--primary) !important;
}
.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--primary) !important;
  font-weight: 900;
}
.video-card{
  transition: all .3s ease-in-out;
  cursor: pointer;
}
.video-card:hover{
  transform: scale(1.02);
}

.login-card{
  backdrop-filter: blur(10px);
  background: rgba(0,0,0,0.4);
  backdrop-filter: saturate(180%) blur(10px);
}